import { isSpecialSubdomain } from "~/utils/helper";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const host = window.location.host;
  const subdomain = host.split(".")[0];

  if (isSpecialSubdomain(subdomain)) {
    return navigateTo("/switch");
  } else {
    return;
  }
});
